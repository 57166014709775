<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>Chart of Accounts Editing</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" small text to="/edit-chart-of-accounts">
          <v-icon left dark>mdi-keyboard-backspace</v-icon>Back
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="7" md="7">
            <v-row>
              <h4>G/L Account Details</h4>
            </v-row>

            <v-row>
              <v-divider></v-divider>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  outlined
                  dense
                  label="G/L Account"
                  v-model="record.AcctCode"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  outlined
                  dense
                  label="Account Name"
                  v-model="record.AcctName"
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-autocomplete
                  outlined
                  dense
                  v-model="record.level"
                  :items="levels"
                  item-text="id"
                  item-value="id"
                  label="Level"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <!-- end of account details -->

            <v-row>
              <h4>G/L Account Location</h4>
            </v-row>

            <v-row>
              <v-divider></v-divider>
            </v-row>

            <v-row dense>
              <v-col cols="6">
                <v-autocomplete
                  outlined
                  dense
                  v-model="record.level"
                  :items="levels"
                  item-text="id"
                  item-value="id"
                  label="Drawer"
                ></v-autocomplete>
              </v-col>

              <v-col cols="6">
                <v-autocomplete
                  outlined
                  dense
                  v-model="record.level"
                  :items="levels"
                  item-text="id"
                  item-value="id"
                  label="Parent Article"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="5" md="5" v-if="accountData.length > 0">
            <v-text-field
              v-model="search"
              label="Search Chart Of Accounts"
              flat
              clear-icon="mdi-close-circle-outline"
            ></v-text-field>
            <v-treeview
              v-model="chartTree"
              color="accent"
              :hoverable="trueValue"
              item-text="AcctName"
              item-children="children"
              :items="accountData"
              item-key="id"
              :search="search"
              open-all
            >
              <template v-slot:label="{ item }">
                <div @click="selectItem(item)">
                  <span style="font-weight: bold">{{ item.AcctCode }}</span>
                  -- {{ item.AcctName }}
                </div>
              </template>
            </v-treeview>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <snackbar ref="snackbar"></snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  computed: {
    ...mapGetters(["user"]),
  },
  data: () => ({
    accountId: null,
    trueValue: true,
    chartTree: [],
    dataItem: {},
    record: {},
    accountData: [],
    openItems: [272, 273],
    drawerAccount: null,
    childrenStatus: true,
    levels: [],
    search: null,
  }),
  watch: {
    "$route.params.id": {
      handler: "setId",
      immediate: true,
    },
  },
  methods: {
    setId(val) {
      this.accountId = val;
    },
    selectItem(item) {
      this.record = item;
    },
    getGlAccounts() {
      const self = this;
      this.loading = true;
      const account = this.accountId;
      this.$store
        .dispatch("get", `/chartofaccounts/` + account)
        .then((res) => {
          self.accountData = res.data;
          self.drawerAccount = res.data[0].AcctName;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  mounted() {
    this.getGlAccounts();
  },
  created() {},
};
</script>